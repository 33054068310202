import { combineReducers } from "redux";
import { orderDataById } from "../utils/orderById";
import * as R from "ramda";

function lang(state = { selection: null, items: {} }, action) {
  switch (action.type) {
    case "LANG_SELECTED":
      return Object.assign({}, state, { selection: action.payload });
    case "LANG_INIT":
      return Object.assign({}, state, { selection: action.payload.defaultLang, items: action.payload.items });
    default:
      return state;
  }
}

export const selectLanguage = (lang) => {
  return { type: "LANG_SELECTED", payload: lang };
};

function meta(state = { initialized: false }, action) {
  switch (action.type) {
    case "INIT_APP_SUCCEEDED":
      return Object.assign({}, state, { data: action.data.data }, { initialized: true });
    case "INIT_APP_METADATA":
      return Object.assign({}, state, { deployment: action.data });
    case "INIT_APP_FAILED":
      return {
        initialized: false,
      };
    default:
      return state;
  }
}

function data(state = {}, action) {
  switch (action.type) {
    case "INIT_APP_SUCCEEDED":
      return Object.assign({}, state, orderDataById(action.data));
    case "INIT_ROUTES_SUCCEEDED":
      return Object.assign({}, state, orderDataById(action.data));
    default:
      return state;
  }
}

function theme(state = { themeName: null }, action) {
  if (action.type === "THEME_INIT") {
    return Object.assign({}, state, { themeName: action.payload });
  } else {
    return state;
  }
}

function eventsPlugin(state = {}, action) {
  if (action.type === "EVENTS_DOWNLOAD_SUCCESS") {
    return Object.assign({}, state, R.objOf(action.lang, action.payload));
  } else {
    return state;
  }
}

function search(state = { isSearchInitialized: false }, action) {
  if (action.type === "INIT_SEARCH_SUCCEEDED") {
    return Object.assign({}, state, { isSearchInitialized: true });
  } else {
    return state;
  }
}

function navigation(state = { current: null, previous: null }, action) {
  if (action.type === "NAVIGATION") {
    return Object.assign({}, { current: action.payload, previous: state.current });
  } else {
    return state;
  }
}

const initialExternalSearchState = {
  status: "READY",
  query: null,
  result: null,
  error: null,
  isUserTriggered: null,
  isQuerying: false,
  isError: false,
  isSuccess: false,
  isNotFound: false,
};
function externalSearch(state = initialExternalSearchState, action) {
  let newState = state;
  switch (action.type) {
    case "EXTERNAL_SEARCH_QUERY":
      newState = { status: "QUERYING", query: action.payload, error: null, payload: null, isUserTriggered: action.isUserTriggered, result: null };
      break;
    case "EXTERNAL_SEARCH_QUERY_SUCCESS":
      newState = { status: "SUCCESS", query: action.query, result: action.payload, error: null, isUserTriggered: action.isUserTriggered };
      break;
    case "EXTERNAL_SEARCH_QUERY_FAILURE":
      newState = { status: "FAILURE", query: action.query, result: null, error: action.error, isUserTriggered: action.isUserTriggered };
      break;
    case "EXTERNAL_SEARCH_QUERY_ERROR":
      newState = { status: "ERROR", query: action.query, result: null, error: action.error, isUserTriggered: action.isUserTriggered };
      break;
    case "EXTERNAL_SEARCH_QUERY_USER_TRIGGERED":
      if (action.query === state.query) {
        newState = { status: "USER_TRIGGERED", query: action.query, result: null, error: null, isUserTriggered: true };
      } else {
        newState = state;
      }
      break;
    case "RESET_EXTERNAL_SEARCH":
      newState = initialExternalSearchState;
      break;
    default:
      newState = state;
  }
  const searchResults = newState.result || {};
  const isQuerying = newState.status === "QUERYING" && searchResults.isUserTriggered;
  const isError = (searchResults.error && searchResults.isUserTriggered) || (newState.status === "SUCCESS" && searchResults.status === "apierror");
  const isSuccess = newState.status === "SUCCESS" && searchResults.status === "success" && searchResults.area;
  const isNotFound = newState.status === "SUCCESS" && searchResults.status === "failure";
  newState = Object.assign({}, newState, { isQuerying, isError, isSuccess, isNotFound });
  return newState;
}

const rootReducer = combineReducers({
  meta,
  theme,
  data,
  lang,
  search,
  eventsPlugin,
  navigation, // only used in kiosk for now
  externalSearch,
});

export default rootReducer;
