import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import "firebase/auth";

const firebaseConfig = require(`./firebase-${process.env.REACT_APP_FIREBASE_CONFIG}.js`).firebaseConfig;
const app = firebase.initializeApp(firebaseConfig);

export const storage = app.storage();
export const functions = app.functions('europe-west1');

export const fire = firebase;
export const databaseRef = firebase.database().ref();
export const storageRef = firebase.storage().ref();
export const STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED;
export const PAUSED = firebase.storage.TaskEvent.PAUSED;
export const RUNNING = firebase.storage.TaskEvent.RUNNING;
export const userAccountRef = (userId) => databaseRef.child("users/"+userId);
export const siteRef = (locationId) => {
  return databaseRef.child(locationId);
}
export const locationsRef = (locationId) => databaseRef.child(locationId+'/location')
export const customerRef = (locationId) => databaseRef.child(locationId+'/customer');
export const langRef = (locationId) => databaseRef.child(locationId+'/customer/lang');
export const localizationRef = (locationId) => databaseRef.child(locationId);
export const pointsRef = (locationId) => databaseRef.child(locationId+'/point');
export const areasRef = (locationId) => databaseRef.child(locationId+'/area');
export const levelsRef = (locationId) => databaseRef.child(locationId+'/level');
export const contactInfoRef = (locationId) => databaseRef.child(locationId+'/contact_info');
export const siteUserRef = (locationId, userId) => firebase.database().ref(locationId+'/users/' + userId)
export const authRef = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
export const emailProvider = firebase.auth();

export const TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;
