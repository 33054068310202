
//FIREBASE
exports.firebaseConfig = {
  apiKey: "AIzaSyCrYcwNmtDIX3q7o6uTVUBdDt1ntcUPZw0",
  authDomain: "palko-production.firebaseapp.com",
  databaseURL: "https://palko-production-ae255.europe-west1.firebasedatabase.app/",
  projectId: "palko-production",
  storageBucket: "palko-production.appspot.com",
  messagingSenderId: "806778908880",
  appId: "1:806778908880:web:d11472e67eb2763f872ed5"
};