import {useEffect} from "react";
import { getAnalyticsConfig } from "../utils/utils"

const matomoScript = (id: string | null) => {
  if (!id) return '// NO MATOMO SITEID PROVIDED'
  return  `
  // START MATOMO CODE
  var _paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['disableCookies']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://35.228.69.77/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', ${id}]);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
  // END MATOMO CODE
  `;
}

// https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx

export const Analytics = () => {
  const siteId = getAnalyticsConfig(window.location.hostname);
  
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = matomoScript(siteId);
    script.type = 'text/javascript'
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [siteId]);
  return null;
}

declare global {
  interface Window { _paq : any; }
}

export const useTracking = (trackingData: any) => {
  const { title } = trackingData;

  useEffect(() => {
    document.title = title;
    window._paq = window._paq || [];
    const _paq = window._paq;
    _paq.push(['deleteCustomVariables', 'page']);
    _paq.push(['setGenerationTimeMs', 0]);
    //_paq.push(['setCustomUrl', window.location.href]);
    _paq.push(['setDocumentTitle', title]);
    _paq.push(['trackPageView']);
  }, [title]);
}