import './App.css';

import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { Analytics } from '../ui-shared/Analytics';
import RootReducer from './reducers';
import RoutesComponent from './Routes';
import RootSaga from './Sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const preloadedState = {};
const store = createStore(RootReducer, preloadedState, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(RootSaga);

export const App = () => {
  return (
    <>
      <Provider store={store}>
        <RoutesComponent />
      </Provider>
      <Analytics />
    </>
  );
};

export default App;
