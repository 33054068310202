const lunr = require('lunr');
require("lunr-languages/lunr.stemmer.support")(lunr);
require('lunr-languages/lunr.multi')(lunr);
require("lunr-languages/lunr.fi")(lunr);
require("lunr-languages/lunr.ru")(lunr);

let idx = null;
export function loadIndex(data) {
  idx = lunr.Index.load(data);
  return idx;
}

const empty = [];
export function search(term, wasSearchTriggered) {
  if (!term) return empty
  if (!idx) return empty;
  let cleanedTerm = term;
  const lenght = term.length;
  if (lenght < 3 && !wasSearchTriggered) return empty;
  cleanedTerm = term + '*';
  if (lenght > 4) cleanedTerm = '*' + term + '*';
  //if (lenght > 8) cleanedTerm = term + '~4';
  let res = idx.search(cleanedTerm)
  if (res.length === 0) {
    const lenght = term.length;
    if (lenght < 2) return empty;
    if (lenght > 2) cleanedTerm = term + '*';
    //if (lenght > 4) cleanedTerm = term + '~1*';
    if (lenght > 4) cleanedTerm = term + '* ' + term + '~1'
    if (lenght > 6) cleanedTerm = term + '* ' + term + '~3';
    res = idx.search(cleanedTerm)
    //if (lenght > 8) cleanedTerm = term + '~4';
  }
  if (!res) return empty;
  return res;
}

