import * as R from 'ramda';

export function orderById(module: any) {
  if (!module) return null;
  const isArray = Array.isArray(module)
  const iterableModule = (isArray? module: R.values(module)) as any[];
  return R.indexBy(R.prop('id'), iterableModule);
}

// var moduleNames = ["area", "category", "customer", "level", "localization", "location", "organization", "point", "users" ]

export function orderDataById(data:any) {
  return Object.assign({}, data, {
    area: orderById(data.area),
    category: orderById(data.category),
    level: orderById(data.level),
    organization: orderById(data.organization),
    point: orderById(data.point)
  })
}