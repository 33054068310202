import axios from "axios";
import { storage } from "../core-data/firebase";

export function getDownloadUrl(url) {
  return storage.ref(url).getDownloadURL();
}

export function getJson(url) {
  return axios.get(url);
}

export function getCarLocation(no, urlTemplate) {
  const url = createLicencePlateQuery(no, urlTemplate);
  const options = {
    method: "GET",
    timeout: 20000,
    headers: { "content-type": "application/x-www-form-urlencoded" },
    url,
  };
  return axios.get(options);
}
