import { useSelector } from "react-redux";

export const useWeb = () => {
  const config = useSelector(s => s.data?.modules?.webTouchpoint);
  const backgroundUrl = config?.backgroundPublicUrl;
  const logoUrl = config?.logoPublicUrl;
  const showExternalSearch = Boolean(String(config?.carSearchPage) === '1');
  const showCategories = Boolean(String(config?.categoriesPage) === '1');
  const showInternalSearch = Boolean(String(config?.searchPage) === '1');
  const showEvents = Boolean(String(config?.eventsPage) === '1');
  const showPaths = Boolean(String(config?.pathsPage) === '1');
  const showAbout = Boolean(String(config?.aboutPage) === '1');
  const isWebDisabled = String(config?.enabled) === '0';
  const frontPage = config?.frontPage || '';
  return { showAbout, showPaths, showExternalSearch, showCategories, showInternalSearch, showEvents, frontPage, isWebDisabled, backgroundUrl,logoUrl};
};
